import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { CorporateHomeComponent } from './pages/corporate-home/corporate-home.component';
import { BookingConfirmationComponent } from './components/booking-confirmation/booking-confirmation.component';

import { ArrivalDetailComponent } from './pages/information/faq/arrival-detail/arrival-detail.component';


const routes: Routes = [
  // { path: 'arrival', component: ArrivalDetailComponent },
  // { path: 'about-us', component: AboutUsComponent },
  // { path: 'ratecard', component: RateCardComponent },
  // { path: 'booking', component: BookingAirportCityComponent },
  // { path: 'my-trips', component: MyTripsComponent },
  // { path: 'my-orders', component: MyOrdersComponent },
  // { path: 'proceed-to-pay', component: ProceedToPayComponent },
  // { path: 'booking-confirmation', component: BookingConfirmationComponent },

  // {
  //   path: 'information',
  //   redirectTo: 'information/faq',
  //   pathMatch: 'full'
  // },
  // { path: 'information/faq', component: FaqComponent },
  // { path: 'information/cancellation-and-refund', component: CancellationAndRefundComponent },
  // { path: 'information/privacy-policy', component: PrivacyPolicyComponent },
  // { path: 'information/terms-and-conditions', component: TermsAndConditionsComponent },

  // { path: 'information/corporate-home', component: CorporateHomeComponent }
];

@NgModule({
  imports: [CommonModule,],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
