import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  NgZone
} from "@angular/core";
import { FormGroup, FormBuilder, Validators, NgModel } from "@angular/forms";
import { PickStateService } from "src/app/core/services/pick-state/pick-state.service";
import { PickAirportService } from "src/app/core/services/pick-airport/pick-airport.service";
import { apis, CORPORATE_APIS } from "src/app/config/apis";
import { CrudService } from "src/app/core/services/crud.service";
import { GoogleAnalyticsService } from "src/app/core/services/google-analytics.service";
import { filter, throttleTime } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Router, NavigationEnd } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

declare var Razorpay: any;
declare var gtag: any;

import * as moment from "moment";
import { MatSnackBar } from '@angular/material';




@Component({
  selector: "app-corporate-departure",
  templateUrl: "./corporate-departure.component.html",
  styleUrls: ["./corporate-departure.component.scss"]
})
export class CorporateDepartureComponent implements OnInit {
  today = new Date();
  minDate;
  userDataForm: FormGroup;
  counter = 1;
  numbers: any;
  postPinData: any;
  postPincode: any;
  loading = false;
  submitted = false;

  dDate = new Date();
  day = new Date().getDate();
  current: any;
  statesMaster = [];
  countryCodeList = [];
  selectedCountry: any;
  cityList = [];

  // tslint:disable-next-line: variable-name
  _airportList = [];

  airportContentArray: any[];

  approximateAmount = 0;

  searchText = "";

  priceDetailsRes;

  @Output()
  bookingInfo = new EventEmitter<Array<string>>();

  @Input()
  serviceType = 2;//departure=2 arrival=1

  constructor(
    private fb: FormBuilder,
    private pickstate: PickStateService,
    private pickairport: PickAirportService,
    private crudService: CrudService,
    private router: Router,
    private ngxSpinner: NgxSpinnerService,
    private ngZone: NgZone,
    private _snackbar: MatSnackBar,
    private googleAnalyticsService: GoogleAnalyticsService,
    private datePipe: DatePipe,

  ) {
  }

  ngOnInit() {
    // this.minDate = this.serviceType === 1 ? moment(new Date()).add(1, "days") : moment(new Date());
    var month = this.dDate.getMonth();
    var year = this.dDate.getFullYear()
    if (this.day < 20 && month === 5 && year === 2020) {
      this.current = this.serviceType === 1 ? new Date(year, month, 20, 10, 33, 30, 0).toISOString().split("T")[0]
        : new Date(year, month, 20, 10, 33, 30, 0).toISOString().split("T")[0];
    }
    else {
      console.log("this.minDate", new Date(year, month, this.day, 10, 33, 30, 0).toISOString().split("T")[0]);
      this.current = this.serviceType === 1 ? new Date(year, month, this.day + 1, 10, 33, 30, 0).toISOString().split("T")[0]
        : new Date(year, month, this.day + 1, 10, 33, 30, 0).toISOString().split("T")[0];
    }
    this.formInit();
    // this.increment();
    // alert(this.serviceType)
  }

  formInit() {
    this.userDataForm = this.fb.group({
      country: ["91"],
      date: [this.current],
      city: ["", Validators.required],
      airport: ["", Validators.required],
      name: ["", Validators.required],
      mobile: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(13)
        ])
      ],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(/\S+@\S+\.\S+/)
        ])
      ],
      pnr: ["", Validators.compose([Validators.required, Validators.maxLength(6), Validators.minLength(6)])],
      program: ["", Validators.required],
      // class: ["", Validators.required],
      // travelType: ["", Validators.required],
      numberOfBags: [1, Validators.required],
      agree: [null, Validators.required]
    });
    this.getMasterData();

    this.userDataForm.statusChanges
      .pipe(filter(() => this.userDataForm.valid))
      .subscribe(() => {
        console.log(this.userDataForm);
        // this.getApproxPrice();
      });
  }

  getMasterData() {
    this.crudService.get(apis.COUNTRY_CODES).subscribe((data: any) => {
      console.log("countryCodeList", data);
      this.countryCodeList = data.codes;


      this.selectedCountry = this.countryCodeList.find(
        c => c.country_code === "91"
      );
    });

    this.pickstate.getState(apis.GET_STATES_AND_RATES).subscribe(data => {
      console.log("masters GET_STATES_AND_RATES", data);
    });

    this.pickairport
      .getAirport(CORPORATE_APIS.GET_CITY)
      .subscribe((data: any) => {
        this.airportList = data.response.airport;
        this.cityList = data.response.region;
      });
  }

  getApproxPrice() {
    this.loading = true;
    const reqBody = {
      transfer_type: 2,
      airport_name: this.userDataForm.get("airport").value.airport_name_id,
      city_name: this.userDataForm.get("city").value.region_id,
      state_name: 0,
      excess_weight_purchased: "no",
      excess_weight: 0,
      bag_weight: 0,
      // no_of_units: this.userDataForm.get("numberOfBags").value,
      no_of_units: 1,
      pincode: this.userDataForm.value.program,
      service_type: this.serviceType
    };
    this.crudService
      .postWithStaticToken(CORPORATE_APIS.GET_APPROX_AMOUNT, reqBody)
      .pipe(throttleTime(250))
      .subscribe(
        (data: any) => {
          this.loading = false;
          console.log("\n\n ========== price ========== \n\n", data);
          this.priceDetailsRes = data;
          if (data && data.price_details && data.price_details.price_with_gst) {
            this.approximateAmount = Math.round(data.price_details.price_with_gst);
          }
        },
        () => (this.loading = false)
      );
  }

  get airportList() {
    const selectedCity = { ...this.userDataForm.get("city").value };
    if (selectedCity) {
      return this._airportList.filter(
        a =>
          Number(a.fk_tbl_city_of_operation_region_id) ===
          selectedCity.region_id
      );
    } else {
      return this._airportList;
    }
    console.log(this.airportList);
  }

  set airportList(list) {
    this._airportList = list;
  }

  afterAirportSelected() {
    console.log(this.userDataForm.value);

    this.extractAirportInformation();
  }

  extractAirportInformation() {
    // this.states = [];
    this.airportContentArray = [];

    const airportDesc = this.userDataForm.value.airport.description;
    if (airportDesc !== "") {
      const airportDescShow = airportDesc.split("&");
      for (const a of airportDescShow) {
        const airportContent = a.split(":");
        this.airportContentArray.push(airportContent);
      }

      console.log(this.airportContentArray);
      this.bookingInfo.emit(this.airportContentArray);
    } else {
    }

    // this.selectedAirportId = this.userDataForm.value.airport.airport_name_id;
    // localStorage.setItem('airport_id', this.selectedAirportId);
    // for (let s of this.statenames) {
    //   if (s.city_id === Number(this.userDataForm.value.airport.fk_tbl_city_of_operation_region_id) && Number(this.selectedAirportId) === s.airport_id) {
    //     this.states.push(s);
    //     this.getApproximateAmount();
    //   }
    // }
  }



  // Counter increment, + button
  increment() {
    if (
      this.userDataForm.value.numberOfBags > 0 &&
      this.userDataForm.value.numberOfBags < 2
    ) {
      this.userDataForm.value.numberOfBags =
        this.userDataForm.value.numberOfBags + 1;
      this.counter = this.userDataForm.value.numberOfBags;
      this.userDataForm.patchValue({ numberOfBags: this.counter });
      this.getApproxPrice();
    }

  }

  // Counter decrement, - button
  decrement() {
    if (
      this.userDataForm.value.numberOfBags > 1 &&
      this.userDataForm.value.numberOfBags < 3
    ) {
      this.userDataForm.value.numberOfBags =
        this.userDataForm.value.numberOfBags - 1;
      this.counter = this.userDataForm.value.numberOfBags;
      this.userDataForm.patchValue({ numberOfBags: this.counter });
      this.getApproxPrice()
    }

  }

  proceed() {
    this.ngxSpinner.show();
    const formValue = { ...this.userDataForm.value };
    console.log(this.userDataForm.value);

    const priceDetails = this.priceDetailsRes;
    // {
    //   status: true,
    //   message: "Successfull",
    //   corporate_name: "Flyporter",
    //   price_details: {
    //     items: {
    //       bag1: "299.00",
    //       bag2: "299.00"
    //     },
    //     excess_weight_price: 0,
    //     outstation_charge: 0,
    //     gst_price: 29.9,
    //     total_luggage_price: 598,
    //     price_with_gst: 627.9
    //   }
    // };

    const itemsOrder = [];
    const bagItems = priceDetails.price_details.items;
    for (const key in bagItems) {
      if (bagItems.hasOwnProperty(key)) {
        const element = bagItems[key];
        itemsOrder.push({
          bag_type: key,
          price: element
        });
      }
    }

    const reqBody = {
      transfer_type: 2,
      airport_name: this.userDataForm.get("airport").value.airport_name_id,
      corporate_type: 4,
      city_name: this.userDataForm.get("city").value.region_id,
      state_name: 0,
      excess_weight_purchased: "no",
      excess_weight: 0,
      bag_weight: 0,
      no_of_units: this.userDataForm.get("numberOfBags").value,
      email: formValue.email,
      pincode: this.userDataForm.value.program,
      items_order: itemsOrder,//[{ bag_type: "bag1", price: this.approximateAmount }],
      gst_amount: priceDetails.price_details.gst_price, // 0 ,
      luggage_price: priceDetails.price_details.price_with_gst, // 450,
      total_luggage_price: priceDetails.price_details.price_with_gst,
      outstation_charge: 0,
      excess_bag_amount: 0,
      service_type: this.serviceType, // 2 for arrival, //1 for departure
      pickup_slot: 1,
      travell_passenger_name: formValue.name,
      travell_passenger_contact: formValue.mobile,
      pick_drop_spots_type: 1,
      building_restriction: {
        "0": 5
      },
      order_date: formValue.date,
      country_code: formValue.country,
      flight_number: formValue.pnr,
    };

    this.crudService
      .postWithStaticToken(CORPORATE_APIS.BOOKING, reqBody)
      .pipe(throttleTime(250))
      .subscribe(
        (data: any) => {
          this.ngxSpinner.hide();
          if (data.status) {
            console.log("\n\n ========== booking ========== \n\n", data);
            this.router.navigate(["/booking-confirmation"], {
              state: {
                description: this.airportContentArray,
                orderId: data.order_number
              }
            });
          }
        },
        () => this.ngxSpinner.hide()
      );
  }


  onSubmit() {
    const formValue = { ...this.userDataForm.value };
    console.log(formValue);
    localStorage.setItem('orderType', this.serviceType.toString());
    this.submitted = true;
    let options = {
      key: environment.razorPayKey,
      // key: 'rzp_test_fOTZDy0HmQGnS7',
      amount: Number(this.approximateAmount) * 100,
      currency: "INR",
      name: "CarterPorter",
      description: "Payment towards Carter",
      image: "https://i.imgur.com/3g7nmJC.png",

      handler: response => {
        // alert(response.razorpay_payment_id);
        this.ngZone.run(() => this.proceed());
      },
      prefill: {
        name: formValue.name,
        email: formValue.email,
        contact: formValue.mobile
      },
      notes: {
        address: "note value"
      },
      theme: {
        color: "#F37254"
      }
    };
    const rzp1 = new Razorpay(options);
    rzp1.open();
    // const RAZORPAY_OPTIONS = {
    //    key: environment.razorPayKey,
    //     amount: 1000, // in currency subunits. Here 1000 = 1000 paise, which equals to ₹10
    //     currency: "INR", // Default is INR. We support more than 90 currencies.
    //     email: formValue.email,
    //     contact: formValue.mobile,
    //     notes: {
    //       address: "Ground Floor, SJR Cyber, Laskar Hosur Road, Bengaluru",
    //     },
    //     order_id: "order_9A33XWu170gUtm",
    //     method: "netbanking",

    //     // method specific fields
    //     bank: "HDFC"

    //   // key: environment.razorPayKey,
    //   // amount: 500,
    //   // name: formValue.name,
    //   // currency: "INR",
    //   // order_id: "order_9A33XWu170gUtm",
    //   // description: "Payment towards Carter",
    //   // image: "https://i.imgur.com/3g7nmJC.png",
    //   // prefill: {
    //   //   name: formValue.name,
    //   //   email: formValue.email,
    //   //   contact: formValue.mobile,
    //   // },
    //   // theme: {
    //   //   color: "#F37254"
    //   // },
    //   // handler : (res) => { console.log(res)}
    // };

    // const rzp1 = new Razorpay(RAZORPAY_OPTIONS);
    // rzp1.open();
    this.googleAnalyticsService.eventEmitter("event", "eventCategory", "Conversion", "ClickedinProceedtopay", 'Proceedtopay');
  }
  // getInfoKey(key: string): string {
  //   return this.toFromFlag + '-' + this.flagger + '-' + key;
  // }
  getInfoKey(key: string): string {
    return key;
  }
  // Pincode Validation
  getPinCode() {
    console.log(new Date(this.userDataForm.value.date))
    console.log(this.datePipe.transform(this.userDataForm.value.date, 'yyyy/MM/dd'))
    this.numbers = /^[0-9]+$/;
    if (this.userDataForm.value.program.length === 6) {
      if (this.userDataForm.value.program.match(this.numbers)) {
        this.postPincode = [{
          "pincode": this.userDataForm.value.program,
          "service_type": 1,
          "airport_name_id": this.userDataForm.value.airport.airport_name_id,
          "city_id": this.userDataForm.value.city.id
        }];
        this.crudService.post(apis.PINCODE_AVAILABILITY, this.postPincode).subscribe(
          (res: any) => {
            if (res.status == false) {
              this._snackbar.open('Sorry! Service not available for the selected area', 'X', {
                duration: 4000, verticalPosition: 'top'

              });
              this.userDataForm.get('program').setValue('');
            }
            else {
              this.getApproxPrice();
            }
            // console.log(res)
          })
      } else {

      }
    } else {
      // this.userDataForm.value.program = '';
      //   this._snackbar.open('Please enter a valid Pincode', 'X', {
      //     duration: 4000, verticalPosition: 'top'
      //   });
    }
  }
}
