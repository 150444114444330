import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PassArrayService } from 'src/app/core/services/pass-array.service';

@Component({
  selector: 'app-booking-delivery-details',
  templateUrl: './booking-delivery-details.component.html',
  styleUrls: ['./booking-delivery-details.component.scss']
})
export class BookingDeliveryDetailsComponent implements OnInit {
  orderObj: any;
  buttonFlag: any = 0;
  deliveryDetails: FormGroup;
  userInput: any;
  loginUser: any;
  userAddress: any;
  savedPincode: any;


  constructor(private fb: FormBuilder, private passFlagButton: PassArrayService) { }

  ngOnInit() {
    // To handle and show error messages
    this.passFlagButton.events$.forEach(event => {
     this.errorsdisplay();
    });

    this.passFlagButton.eventsForGetUserDetails$.forEach(e=>{
        this.keepDataInPage()
    })
    if (localStorage.orderObj) {
      this.orderObj = JSON.parse(localStorage.orderObj);
    }
    if (localStorage.userInput) {
      this.userInput = JSON.parse(localStorage.userInput);
    }

    this.deliveryDetails = this.fb.group({
      contactPerson: [],
      contactNumber: [''],
      houseNO: ['', Validators.required],
      streetline1: ['', Validators.required],
      streetline2: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalcode: [this.userInput.pincode],
      comments: ['']
    });

    this.keepDataInPage();

    this.passFlagButton.currentStatusButton.subscribe(buttonFlag => this.buttonFlag = buttonFlag);
    if(this.deliveryDetails.valid){
      this.passFlagButton.passFlagButton(1);
    }

  }
  get f() { return this.deliveryDetails.controls; }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    if (this.deliveryDetails.valid) {
      let obj = {
        contactPerson: this.deliveryDetails.value.contactPerson,
        contactNumber: this.deliveryDetails.value.contactNumber,
        houseNo: this.deliveryDetails.value.houseNO,
        streetLine1: this.deliveryDetails.value.streetline1,
        streetLine2: this.deliveryDetails.value.streetline2,
        city: this.deliveryDetails.value.city,
        state: this.deliveryDetails.value.state,
        postalcode: this.deliveryDetails.value.postalcode,
        comments: this.deliveryDetails.value.comments
      }
      const key = 'userAddress';
      localStorage.setItem(key, JSON.stringify(obj));
      this.buttonFlag = 1;
      this.passFlagButton.passFlagButton(this.buttonFlag);
    } else {
      // if(!localStorage.getItem('contactDetails')){
      const controls = this.deliveryDetails.controls;
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      // }
      this.passFlagButton.passFlagButton('');

    }
  }

  keepDataInPage(){
      if(localStorage.loginUserDetails){
        this.loginUser = JSON.parse(localStorage.loginUserDetails);
        this.userAddress = this.loginUser.saved_address.last_order_address;
        this.savedPincode = this.userAddress.pincode;
        if(!this.deliveryDetails.value.contactPerson){
          this.deliveryDetails.patchValue({ contactPerson: this.loginUser.customer_detail.name });
        }
        if(!this.deliveryDetails.value.contactNumber){
          this.deliveryDetails.patchValue({ contactNumber: this.loginUser.customer_detail.mobile });
        }
      }

      if(localStorage.userAddress){
        let userAddress = JSON.parse(localStorage.userAddress);
        this.deliveryDetails.patchValue({ contactPerson: userAddress.contactPerson });
        this.deliveryDetails.patchValue({ contactNumber:userAddress.contactNumber });
        this.deliveryDetails.patchValue({ houseNO:userAddress.houseNo });
        this.deliveryDetails.patchValue({ streetline1:userAddress.streetLine1 });
        this.deliveryDetails.patchValue({ streetline2:userAddress.streetLine2 });
        this.deliveryDetails.patchValue({ city:userAddress.city });
        this.deliveryDetails.patchValue({ state:userAddress.state });
        this.deliveryDetails.patchValue({ postalcode:userAddress.postalcode });
        this.deliveryDetails.patchValue({ comments:userAddress.comments });

      }
  }


  // To display error messages
  errorsdisplay() {
    const controls = this.deliveryDetails.controls;
    Object.keys(controls).forEach(controlName =>
      controls[controlName].markAsTouched()
    );
  }
}
