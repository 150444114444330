import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/pages/homepage/home/home.component';
import { BookingConfirmationComponent } from './modules/home/components/booking-confirmation/booking-confirmation.component';

import { ArrivalDetailComponent } from './modules/home/pages/information/faq/arrival-detail/arrival-detail.component';
import { DepartureDetailComponent } from './modules/home/pages/information/faq/departure-detail/departure-detail.component';

import { PrivacyPolicyComponent } from './modules/home/pages/information/faq/privacy-policy/privacy-policy.component';

import { TermsAndConditionsComponent } from './modules/home/pages/information/faq/terms-and-conditions/terms-and-conditions.component'

import { CancellationAndRefundComponent } from './modules/home/pages/information/faq/cancellation-and-refund/cancellation-and-refund.component'

import { FaqComponent } from './modules/home/pages/information/faq/faq/faq.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: 'home', component: HomeComponent },
  { path: 'booking-confirmation', component: BookingConfirmationComponent },
  { path: 'arrival-details', component: ArrivalDetailComponent },
  { path: 'depature-details', component: DepartureDetailComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'cancellation-and-refund', component: CancellationAndRefundComponent },
  { path: 'faq', component: FaqComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
