import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-video-block',
  templateUrl: './home-video-block.component.html',
  styleUrls: ['./home-video-block.component.scss']
})
export class HomeVideoBlockComponent implements OnInit {

  id: '3GlhCz0sDrM';
  currentPlayingVideo: HTMLVideoElement;

  playerVars = {
    cc_lang_pref: 'en'
  };
  public player;
  public ytEvent;

  constructor() { }

  ngOnInit() {
  }

  onStateChange(event) {
    this.ytEvent = event.data;
  }
 
  onPlayingVideo(event) {
    event.preventDefault();
    // play the first video that is chosen by the user
    if (this.currentPlayingVideo === undefined) {
        this.currentPlayingVideo = event.target;
        this.currentPlayingVideo.play();
    } else {
    // if the user plays a new video, pause the last one and play the new one
        if (event.target !== this.currentPlayingVideo) {
            this.currentPlayingVideo.pause();
            this.currentPlayingVideo = event.target;
            this.currentPlayingVideo.play();
        }
    }
}

  savePlayer(player) {
    this.player = player;
  }

}
