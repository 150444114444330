import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  airportInfo = [];

  bookingInfo(i){
    this.airportInfo = i;
  }

  isArrivalService = false;

  constructor() { }

  ngOnInit() {
    window.scroll(0, 0);
    localStorage.removeItem('localOutstationFlag');
    localStorage.removeItem('userInput');
    localStorage.removeItem('toFromFlag');
    localStorage.removeItem('luggageData');
    localStorage.removeItem('userAddress');
    localStorage.removeItem('contactDetails');

    localStorage.removeItem('keepArrayOfluggae');
    localStorage.removeItem('keepweightOfLuggage');
    localStorage.removeItem('selectedDate');
    localStorage.removeItem('saveObj');
    localStorage.removeItem('noOfBags');
    localStorage.removeItem('orderSub');
    localStorage.removeItem('outstationResponse');
    localStorage.removeItem('outStationArray');
    localStorage.removeItem('deliveryDate');
    localStorage.removeItem('region_id');
    localStorage.removeItem('showDate');
    localStorage.removeItem('timeOnwards');
    localStorage.removeItem('insurancePrice');
    localStorage.removeItem('totalPrice');
    localStorage.removeItem('keepDate');

  }

}
