import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { min } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pickup-delivery-details',
  templateUrl: './pickup-delivery-details.component.html',
  styleUrls: ['./pickup-delivery-details.component.scss'],
})


export class PickupDeliveryDetailsComponent implements OnInit {
  orderObj: any;
  flag = false;
  userAddress: any;
  contactDetails: any;
  userInput: any;
  loginUserDetails: any;
  url = this.router.url;
  toFromFlag: any;
  localOutstation: any;
  carterTime: any;
  selectedDateBooking: any;
  pickUpDate: any;

  constructor(public router: Router,  private datePipe: DatePipe) { }

  ngOnInit() {
    if (localStorage.orderObj) {
      this.orderObj = JSON.parse(localStorage.orderObj);
    }

    if (localStorage.userAddress) {
      this.userAddress = JSON.parse(localStorage.userAddress);
    }

    if (localStorage.contactDetails) {
      this.contactDetails = JSON.parse(localStorage.contactDetails);
      let hour = this.contactDetails.carterTime.hour;
      let minute = this.contactDetails.carterTime.minute;
      hour = this.contactDetails.carterTime.hour.toString();
      if(hour.length === 1) {
        hour = "0" + hour;
      }
      minute = this.contactDetails.carterTime.minute.toString();
      if(minute.length === 1) {
        minute = "0" + minute;
      }
      this.carterTime = hour + " : " + minute;
    }
    if (localStorage.userInput) {
      this.userInput = JSON.parse(localStorage.userInput);
      this.pickUpDate = this.userInput.date;

    }
    if (localStorage.loginUserDetails) {
      this.loginUserDetails = JSON.parse(localStorage.loginUserDetails);
    }
    if (localStorage.toFromFlag) {
      this.toFromFlag = localStorage.getItem('toFromFlag');
    }
    if (localStorage.localOutstationFlag) {
      this.localOutstation = localStorage.getItem('localOutstationFlag');
    }

    if (localStorage.selectedDate) {
      this.selectedDateBooking = localStorage.getItem('selectedDate');
      if(this.toFromFlag === '2' && this.localOutstation === '2') {
        let date = this.selectedDateBooking;
        let dateformat = new Date(date);
        let year = new Date().getFullYear();
        this.pickUpDate = this.selectedDateBooking + " " + year;
        date = dateformat.setDate(dateformat.getDate() + 3);
        date = this.datePipe.transform(date, 'dd MMM');
        date = date + " " + year;
        dateformat = new Date(date);
        this.selectedDateBooking = this.datePipe.transform(dateformat, 'dd MMM yyyy');
      }
      if(this.toFromFlag === '1') {
        let date = this.selectedDateBooking;
        let dateformat = new Date(date);
        let year = new Date().getFullYear();
        date = date + " " + year;
        dateformat = new Date(date);
        this.selectedDateBooking = this.datePipe.transform(dateformat, 'dd MMM yyyy');
      }
      if(this.toFromFlag === '2' && this.localOutstation === '1') {
        let date = this.selectedDateBooking;
        let dateformat = new Date(date);
        let year = new Date().getFullYear();
        date = date + " " + year;
        dateformat = new Date(date);
        this.pickUpDate = this.datePipe.transform(dateformat, 'dd MMM yyyy');
        this.selectedDateBooking = this.datePipe.transform(dateformat, 'dd MMM yyyy');
      }
    }

  }

  setFlag(data) {
    if (this.flag) {
      this.flag = false;
    } else {
      this.flag = data;
    }

  }
}
