import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HomeCitywiseCostService {

  constructor( public http: HttpClient) { }

  getCityWiseCostDetails() {
    return this.http.get('../../../assets/json/city-rate.json');
  }
}
