import { Component, OnInit, Input } from '@angular/core';
import { PickStateService } from '../../../../../../core/services/pick-state/pick-state.service';
import { PickAirportService } from '../../../../../../core/services/pick-airport/pick-airport.service';
import { PickTimeslotService } from '../../../../../../core/services/pick-timeslot/pick-timeslot.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgbDateNativeAdapter, NgbDateAdapter, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { PassFlagService } from '../../../../../../core/services/passFlagService/pass-flag.service';
import { DatePipe } from '@angular/common';
import { apis } from '../../../../../../config/apis';

@Component({
  selector: 'app-city-transfer',
  templateUrl: './city-transfer.component.html',
  styleUrls: ['./city-transfer.component.scss'],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class CityTransferComponent implements OnInit {

  model1: Date;
  model2: Date;
  statedata: any;
  states: any;
  airportdata: any;
  airportnames: any;
  counter = 1;
  timeSlotData: any;
  timeSlotData1: any;
  mode = true;
  userDataForm: FormGroup;
  model: NgbDateStruct;

  today = this.datePipe.transform(new Date(), 'd MMM y');
  date: Date = new Date();

  @Input() passTabFlag: string;
  toFromFlag: number;


  constructor( private pickstate: PickStateService, private pickairport: PickAirportService,
               private picktimeslot: PickTimeslotService, private calendar: NgbCalendar,
               private passFlag: PassFlagService, private datePipe: DatePipe, public fb: FormBuilder) { }

  ngOnInit() {
    this.userDataForm = this.fb.group({
      city: ['', Validators.required],
      state: ['', Validators.required],
      pincode: ['', [Validators.required]],
      numberOfBags: [1, Validators.required],
      date: ['', Validators.required],
      airport: ['', Validators.required],
      timeslot: ['', Validators.required]
    });

    this.userDataForm.get('state').disable();
    this.userDataForm.get('pincode').disable();
    this.setPassingFlag(1);
  }

  pickStateFunction() {
    this.pickstate.getState(apis.GET_STATES_AND_RATES).subscribe( data => {
      this.statedata = data;
      this.states = this.statedata.statedetails;
      console.log(this.states);
    });
  }
  pickAirportFunction() {
    this.pickairport.getAirport(apis.GET_CITY).subscribe( data => {
      this.airportdata = data;
      this.airportnames = this.airportdata.airport;
      console.log(this.airportdata);
    });
  }
  pickTimeSlotFunction() {
    this.picktimeslot.getTimeslot(apis.TIME_SLOTS).subscribe( data => {
      this.timeSlotData = data;
      this.timeSlotData1 = this.timeSlotData.time;
    });
  }
  increment() {
    if ((this.userDataForm.value.numberOfBags  > 0) && (this.userDataForm.value.numberOfBags < 8)) {
      this.userDataForm.value.numberOfBags = this.userDataForm.value.numberOfBags + 1;
      this.counter = this.userDataForm.value.numberOfBags;
      this.userDataForm.patchValue({numberOfBags: this.counter });
    }
  }
  decrement() {
    if ((this.userDataForm.value.numberOfBags > 1) && (this.userDataForm.value.numberOfBags < 9)) {
      this.userDataForm.value.numberOfBags = this.userDataForm.value.numberOfBags - 1;
      this.counter = this.userDataForm.value.numberOfBags;
      this.userDataForm.patchValue({numberOfBags: this.counter });
      }
  }
  isDisabled(): boolean {
    return true;
  }
  isDisabledButton(flag: boolean): boolean {
      return flag;
  }
  onSubmit(form: FormGroup) {

  }
  selectToday() {
    this.model = this.calendar.getToday();
  }

  setFlag(flag: any) {
    if (flag === '2') {
      this.userDataForm.get('state').enable();
      this.userDataForm.get('pincode').enable();
    } else {
      this.userDataForm.get('state').disable();
      this.userDataForm.get('pincode').disable();
    }
  }

  passingFlag() {
    this.passFlag.setOption(this.passTabFlag);
  }
  setPassingFlag(flag: number) {
    this.toFromFlag = flag;
  }

}
