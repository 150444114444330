import { Component, OnInit, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-corporate-user-details",
  templateUrl: "./corporate-user-details.component.html",
  styleUrls: ["./corporate-user-details.component.scss"]
})
export class CorporateUserDetailsComponent implements OnInit {
  @Output()
  bookingInfo = new EventEmitter<Array<string>>();

  @Output()
  isArrivalService = new EventEmitter<boolean>();

  tabChange(t) {
    // console.log(ev)
    console.log(t);

    setTimeout(
      () => {
        if (t.activeId === "departure") {
          console.log("tabs departure")
          this.isArrivalService.emit(false);
        } else if (t.activeId === "arrival") {
          console.log("tabs arrival")
          this.isArrivalService.emit(true);
        }
      }, 10
    )


  }

  constructor() { }

  ngOnInit() { }

}
