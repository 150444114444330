import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CrudService } from 'src/app/core/services/crud.service';

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss']
})
export class BookingConfirmationComponent {
  orderNumber: any;
  orderType: any;
  airportInfo = [
    [
      "LOCATION AT AIRPORT",
      " CARTERX KIOSK 24/7 AT ARRIVALS opposite Relay"
    ],
    [
      "FOR DEPARTURES",
      " Order Delivery up to Airline Check-in counters"
    ],
    [
      "FOR ARRIVAL",
      "Order Pick up at Luggage Carousel/baggage belts (Domestic and International)"
    ],
    [
      "LUGGAGE STORAGE",
      " Available"
    ],
    [
      "INTER TERMINAL TRANSFER",
      " N/A"
    ]
  ];

  constructor(private router: Router, private crudService: CrudService) {
    if (this.router.getCurrentNavigation().extras.state) {
      console.log(this.router.getCurrentNavigation().extras.state.description);
      // should log out 'bar'
      this.airportInfo = this.router.getCurrentNavigation().extras.state.description;
      this.orderNumber = this.router.getCurrentNavigation().extras.state.orderId;

    } else {
      this.router.navigate(['/home'])
    }
  }

  ngOnInit() {
    this.orderType = localStorage.getItem('orderType')
  }

  downloadPdf() {
    this.crudService.get('r=order/order-confrimation-corporate-pdf&order_number=' + this.orderNumber).subscribe(
      (res: any) => {
        console.log(res)

        if (res.status) {
          window.open(res.path, '_blank')
        }
      }
    )


  }


}
