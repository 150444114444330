import { Component } from '@angular/core';
import { PassArrayService } from './core/services/pass-array.service';
import { Router, NavigationEnd } from '@angular/router';

declare var gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  loading: boolean = false;

  constructor(public getLoader: PassArrayService, public router: Router) {
    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        gtag('config', 'AW-726008753',
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    });
  }
  title = 'CarterX';

  ngOnInit() {
    this.getLoader.currentLoaderFlag.subscribe(loader => this.loading = loader);
  }

}
