import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomeComponent } from "./pages/homepage/home/home.component";
import { HomeStaticBlockComponent } from "./pages/homepage/home-static-block/home-static-block.component";
import { HomeCitywiseCostComponent } from "./pages/homepage/home-citywise-cost/home-citywise-cost.component";
import { HttpClientModule } from "@angular/common/http";
import { HomeVideoBlockComponent } from "./pages/homepage/home-video-block/home-video-block.component";
// import { AirportTransferComponent } from './pages/homepage/home-details/airport-transfer/airport-transfer.component';
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CityTransferComponent } from "./pages/homepage/home-details/city-transfer/city-transfer.component";
import { UserInputDetailsComponent } from "./pages/homepage/home-details/user-input-details/user-input-details.component";
import { HomeRoutingModule } from "./home-routing.module";
import { AboutUsComponent } from "./pages/about-us/about-us.component";
// import { RateCardComponent } from './pages/rate-card/rate-card.component';
// import { BookingPaymentDetailsComponent } from 'src/app/shared/components/booking-payment-details/booking-payment-details.component';
import { BookingLuggageDetailsComponent } from "src/app/shared/components/booking-luggage-details/booking-luggage-details.component";
import { BookingPickupDetailsComponent } from "src/app/shared/components/booking-pickup-details/booking-pickup-details.component";
import { BookingDeliveryDetailsComponent } from "src/app/shared/components/booking-delivery-details/booking-delivery-details.component";
// import { MyTripsComponent } from "./components/my-trips/my-trips.component";
import { ProceedToPayComponent } from "./components/proceed-to-pay/proceed-to-pay.component";
import { BookingConfirmationComponent } from "./components/booking-confirmation/booking-confirmation.component";
import { MyOrdersComponent } from "./components/my-orders/my-orders.component";
import { PickupDeliveryDetailsComponent } from "src/app/shared/components/pickup-delivery-details/pickup-delivery-details.component";
// import { LoginComponent } from 'src/app/shared/modals/login/login.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { MatDialogModule } from "@angular/material";
// import { RegisterComponent } from 'src/app/shared/modals/register/register.component';
import { VerifyOtpComponent } from "src/app/shared/modals/verify-otp/verify-otp.component";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { CancellationAndRefundComponent } from "./pages/information/faq/cancellation-and-refund/cancellation-and-refund.component";
import { PopUpComponent } from "src/app/shared/modals/pop-up/pop-up.component";
import { FaqComponent } from './pages/information/faq/faq/faq.component';
import { PrivacyPolicyComponent } from './pages/information/faq/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './pages/information/faq/terms-and-conditions/terms-and-conditions.component';
import { InfoDialogDirective } from "src/app/shared/directives/info-dialog.directive";
import { CorporateHomeComponent } from "./pages/corporate-home/corporate-home.component";
import { CorporateHeaderComponent } from "src/app/core/corporate-header/corporate-header.component";
import { CorporateFooterComponent } from "src/app/core/corporate-footer/corporate-footer.component";
import { CorporateConfirmationComponent } from "./components/corporate-confirmation/corporate-confirmation.component";
import { CorporateUserDetailsComponent } from "./pages/homepage/home-details/corporate-user-details/corporate-user-details.component";
import { CorporateDepartureComponent } from "./pages/homepage/home-details/corporate-departure/corporate-departure.component";
import { CorporateArrivalComponent } from "./pages/homepage/home-details/corporate-arrival/corporate-arrival.component";
// import { LuggageGuideComponent } from 'src/app/shared/modals/luggage-guide/luggage-guide.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxYoutubePlayerModule } from "ngx-youtube-player";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NumberDirective } from "../../shared/directives/number.directive";
import { TimeformatPipe } from "../../shared/pipes/timeformat.pipe";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { Ng2LoadingSpinnerModule } from "ng2-loading-spinner";
import { Ng4LoadingSpinnerModule } from "ng4-loading-spinner";
import { FaqContentComponent } from "./pages/information/faq/faq/components/faq-content/faq-content.component";
import { MatFormFieldModule, MatSelectModule } from "@angular/material";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ConfirmModalComponent } from "src/app/shared/modals/confirm-modal/confirm-modal.component";
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { PORTERX_DATE_FORMATS } from "src/app/shared/material/date-format";
import { NgxFormAddonsModule } from "ngx-form-addons";
import { FilterArrayPipe } from "src/app/shared/pipes/filter-array.pipe";

import { NgxCollapseModule } from 'ngx-collapse';
import { ArrivalDetailComponent } from './pages/information/faq/arrival-detail/arrival-detail.component';
import { DepartureDetailComponent } from './pages/information/faq/departure-detail/departure-detail.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [HomeComponent,
    HomeStaticBlockComponent,
    HomeCitywiseCostComponent,
    HomeVideoBlockComponent,
    // AirportTransferComponent,
    CityTransferComponent,
    UserInputDetailsComponent,
    AboutUsComponent,
    // RateCardComponent,
    // BookingPaymentDetailsComponent,
    // BookingAirportCityComponent,
    BookingLuggageDetailsComponent,
    BookingPickupDetailsComponent,
    BookingDeliveryDetailsComponent,
    // MyTripsComponent,
    ProceedToPayComponent,
    BookingConfirmationComponent,
    MyOrdersComponent,
    PickupDeliveryDetailsComponent,
    // LoginComponent,
    // RegisterComponent,
    VerifyOtpComponent,
    CancellationAndRefundComponent,
    PopUpComponent,
    FaqComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    ConfirmModalComponent,

    // directives
    InfoDialogDirective,
    NumberDirective,

    // pipes
    TimeformatPipe,
    FilterArrayPipe,

    CorporateHomeComponent,
    CorporateHeaderComponent,
    CorporateFooterComponent,
    CorporateConfirmationComponent,
    CorporateUserDetailsComponent,
    CorporateDepartureComponent,
    CorporateArrivalComponent,
    // LuggageGuideComponent,
    FaqContentComponent,
    ArrivalDetailComponent,
    DepartureDetailComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgxCollapseModule,
    HomeRoutingModule,
    MatDialogModule,
    NgxSpinnerModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    NgxLoadingModule.forRoot({}),
    NgxYoutubePlayerModule.forRoot(),
    Ng2LoadingSpinnerModule.forRoot({}),
    Ng4LoadingSpinnerModule.forRoot(),
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    NgxFormAddonsModule
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: PORTERX_DATE_FORMATS },
  ],
  entryComponents: [PopUpComponent, ConfirmModalComponent]
})
export class HomeModule { }


