import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-input-details',
  templateUrl: './user-input-details.component.html',
  styleUrls: ['./user-input-details.component.scss']
})
export class UserInputDetailsComponent implements OnInit {

  flag: any;
  flagCity: any;
  tabFlag: any;

  constructor() { }

  ngOnInit() {
    this.tabToggleAirport();
  }
  tabToggleAirport() {
    this.flag = 0;
    this.tabFlag = 1;
  }
  tabToggleCity() {
    this.flagCity = 1;
    this.tabFlag = 2;
    console.log(this.tabFlag);
  }
  setTabFlag(flag: number) {
    this.tabFlag = 2;
  }
}
