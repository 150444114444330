// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //* For QA
  // **********************************************************
  baseUrl: "https://hyd.carterx.in/index.php?",
  razorPayKey: "rzp_test_AjXfMvJKPx2MfY",
  // **********************************************************

  //* For Production
  // **********************************************************
  // baseUrl: "https://app.carterx.in/index.php?",
  // razorPayKey: "rzp_live_LthnWTU5SuA0Hg",
  // **********************************************************

  // baseUrl: 'https://app.carterx.in/index.php?',
  // baseUrl: 'http://app.carterx.in/',
  // razorPayKey: 'rzp_live_LthnWTU5SuA0Hg'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
