import { Component, OnInit } from '@angular/core';
import { HomeCitywiseCostService } from '../../../../../core/services/home-citywise-cost/home-citywise-cost.service';

@Component({
  selector: 'app-home-citywise-cost',
  templateUrl: './home-citywise-cost.component.html',
  styleUrls: ['./home-citywise-cost.component.scss']
})
export class HomeCitywiseCostComponent implements OnInit {

  citydetails: any;
  cityrate: any;
  imagesrc: any;
  indx: any;
  isCollapsed = true;
  toggleButton = 'VIEW ALL';

  constructor(public citycost: HomeCitywiseCostService) { }

  ngOnInit() {
    this.getCost();
  }
  getCost() {
    this.citycost.getCityWiseCostDetails().subscribe(data => {
        this.citydetails = data;
        this.cityrate = this.citydetails.cityrate;
        console.log(this.citydetails);
        console.log(this.citydetails.cityrate);
    });
  }
  toggleButtonText(i: any) {
    this.indx = i;
    if (this.toggleButton === 'VIEW ALL') {
    this.toggleButton = 'VIEW LESS';
    } else {
      this.toggleButton = 'VIEW ALL';
    }
  }

}
