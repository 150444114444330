import { Component, OnInit, ViewChild } from "@angular/core";


@Component({
  selector: "app-booking-luggage-details",
  templateUrl: "./booking-luggage-details.component.html",
  styleUrls: ["./booking-luggage-details.component.scss"]
})
export class BookingLuggageDetailsComponent {

}
